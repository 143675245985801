import { Calendar, Settings, Sliders } from 'react-feather';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/img/icons/logo.svg';
import React from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const navItems = [
  // {
  //   icon: <Activity />,
  //   name: 'Quotes',
  //   path: '/quotes',
  // },
  {
    icon: <Calendar />,
    name: 'Calls',
    path: '/calls',
  },
  {
    icon: <Sliders />,
    name: 'Insights',
    path: '/insights',
  },
  {
    icon: <Settings />,
    name: 'Settings',
    path: '/integrations',
  },
];

// const navItemsAdmins = [
//   {
//     icon: <Upload />,
//     name: 'Insert Data',
//     path: '/insert',
//   },
//   {
//     icon: <Clock />,
//     name: 'Jobs',
//     path: '/jobs',
//   },
// ];

const Sidebar = () => {
  const location = useLocation();
  const sideBarRef = React.useRef();

  const renderMenus = (items) => {
    return items.map((item) => {
      const isActive = location.pathname.includes(item.path);
      return (
        <li key={item.path} className={`sidebar-item ${isActive ? 'active' : ''}`}>
          <NavLink className='sidebar-link' to={item.path}>
            {item.icon}
            <span className='align-middle'>{item.name}</span>
          </NavLink>
        </li>
      );
    });
  };

  useOutsideClick(sideBarRef, (e) => {
    if (e.target.classList.contains('sidebar-toggle') || e.target.parentElement?.classList.contains('sidebar-toggle')) return;
    if (document.querySelector('nav.sidebar')?.classList.contains('collapsed')) {
      document.querySelector('nav.sidebar')?.classList.remove('collapsed');
    }
  });

  return (
    <nav id='sidebar' className='sidebar js-sidebar' ref={sideBarRef}>
      <div className='sidebar-content js-simplebar'>
        <Link className='sidebar-brand d-flex align-items-center' to='/'>
          <img src={logo} height='30' className='me-2' alt='logo' />
          <span className='align-middle'>Luxa</span>
        </Link>
        <ul className='sidebar-nav'>
          <li className='sidebar-header'>Pages</li>
          {renderMenus(navItems)}
          {/* <li className='sidebar-header'>Administrative</li>
          {renderMenus(navItemsAdmins)} */}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
