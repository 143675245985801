import packageJson from './../../package.json';

const generic = {
  production: {
    url_protocol: 'https',
    api_domain: 'api.luxa.ai',
  },
  development: {
    url_protocol: 'https',
    api_domain: 'api.luxa.ai',
  },
  local: {
    url_protocol: 'http',
    api_domain: 'localhost:4000',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    API_PREFIX: '',
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Bearer',
  },
};
