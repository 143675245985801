import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Modal } from 'rsuite';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';

const GongContent = ({ handleClose, quote }) => {
  const alert = useAlert();
  const [videoUrl, setVideoUrl] = useState();

  const getVideoUrl = async (quoteID) => {
    try {
      const res = await Api.get(`/insights/quote/video/${quoteID}`);
      setVideoUrl(res.data.video_url);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const tryToPlay = () => {
    try {
      const video = document.querySelector('.gong-video');
      if (video) {
        video.currentTime = quote.timestamp / 1000;
        video.play();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVideoUrl(quote.id);
  }, [quote]);

  useEffect(() => {
    tryToPlay();
  }, [videoUrl]);

  return (
    <Modal size='lg' open={true} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Gong Video</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center align-items-center'>
        {videoUrl && (
          <video controls autoPlay name='media' className='gong-video'>
            <source src={videoUrl} type='video/mp4' />
          </video>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance='subtle'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GongContent;
