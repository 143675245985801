const CardInfoBlock = ({ title, icon, value, secondInfo, className = '' }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>{title}</h5>
          </div>
          <div className='col-auto'>
            <div className='stat text-primary'>{icon}</div>
          </div>
        </div>
        <h1 className='mt-1 mb-3'>{value}</h1>
        {secondInfo && (
          <div class='mb-0'>
            <span class='text-muted'>{secondInfo}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardInfoBlock;
