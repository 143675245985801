import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';
import { Button, CheckPicker, DateRangePicker, Modal, Pagination } from 'rsuite';
import TableHeader from 'shared/components/TableHeader';
import Loading from 'shared/components/Loading';

const listHeaders = [
  { name: 'Call Title', key: 'calls_title', classContentName: '' },
  { name: 'Date', key: 'calls_started', classContentName: '' },
  { name: 'Customer', key: 'account_name', classContentName: '' },
  { name: '# of insights', key: 'insightscount', classContentName: 'justify-content-center' },
];

const Calls = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const [customers, setCustomers] = useState([]);
  const [sort, setSort] = useState({ key: 'calls_started', bool: true });
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [showGongSuccess, setShowGongSuccess] = useState(location?.state?.gong_success || false);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 1,
    customers: [],
    startDate: null,
    endDate: null,
  });

  const getCustomers = async () => {
    try {
      const res = await Api.get('/users/company/customers');
      const data = res.data.map((item) => ({ label: item.account_name, value: item.account_id }));
      setCustomers(data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const getCalls = async () => {
    try {
      setFetching(true);
      const params = { ...sort, ...filters };
      const res = await Api.get('/users/company/calls', { params });
      setData(res.data);
    } catch (err) {
      alert.error(getError(err));
    } finally {
      setFetching(false);
    }
  };

  const onSelectCustomers = (customers) => {
    setFilters({ ...filters, customers });
  };

  const onSelectDate = (dateRange) => {
    if (!dateRange) {
      setFilters({ ...filters, dateRange: { startDate: null, endDate: null } });
      return;
    }
    const [start, end] = dateRange;
    setFilters({ ...filters, startDate: start, endDate: end });
  };

  const goToDetails = (item) => {
    navigate(`/calls/${item.calls_id}`);
  };

  const onHandleCloseSuccess = () => {
    setShowGongSuccess(false);
    getCalls();
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    getCalls();
  }, [sort, filters]);

  return (
    <div className='container-fluid p-0'>
      <div className='d-flex flex-wrap mb-3 align-items-center justify-content-between'>
        <h1 className='h3 d-flex align-items-center'>Calls {fetching && <Loading className='ms-2' />}</h1>
        <div className='d-flex flex-wrap align-items-center justify-content-end col-12 col-md-6'>
          <CheckPicker
            label='Customers'
            className='col-12 col-md-5 col-md-none'
            data={customers}
            onChange={onSelectCustomers}
          />
          <DateRangePicker
            size='md'
            className='ms-0 ms-md-2 mt-2 mt-md-0 col-12 col-md-6'
            placeholder='Select Date Range'
            showOneCalendar
            onChange={onSelectDate}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>List of Calls</h5>
            </div>
            <div className='card-body'>
              <div className='overflow-auto'>
                <table className='table table-hover'>
                  <TableHeader items={listHeaders} sort={sort} setSort={setSort} />
                  <tbody>
                    {data?.data?.map((item, i) => {
                      return (
                        <tr key={i} className='cursor-pointer' onClick={goToDetails.bind(null, item)}>
                          <td>{item.calls_title}</td>
                          <td>{dayjs(item.calls_started).format('DD MMM YYYY')}</td>
                          <td>{item.account_name || '-'}</td>
                          <td className='d-flex justify-content-center'>{item.insightscount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='d-flex align-items-center justify-content-around justify-content-md-end py-3'>
                <Pagination
                  prev
                  next
                  size='md'
                  total={data?.total || 0}
                  limit={filters.limit}
                  activePage={filters.offset}
                  onChangePage={(offset) => setFilters({ ...filters, offset })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size='xs' backdrop='static' role='alertdialog' open={showGongSuccess} onClose={onHandleCloseSuccess}>
        <Modal.Body>Gong successfully connected</Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <Button onClick={onHandleCloseSuccess} appearance='primary'>
              Ok
            </Button>
            <Button onClick={onHandleCloseSuccess} appearance='subtle'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calls;
