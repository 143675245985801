import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';
import { getProfile } from 'app/actions/authActions';
import { useDispatch } from 'react-redux';
import { onSetAccount } from 'app/actions/auth';
import Loading from 'shared/components/Loading';

const GongCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const alert = useAlert();

  const onGongCallback = async () => {
    try {
      await Api.post('/gong/login', { code });
      const user = await getProfile();
      await dispatch(onSetAccount(user));
      navigate(`/calls`, { state: { gong_success: true } });
    } catch (err) {
      alert.error(getError(err));
      navigate('/integrations');
    }
  };

  useEffect(() => {
    onGongCallback();
  }, []);

  return <Loading />;
};

export default GongCallback;
