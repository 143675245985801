import AuthLayout from 'shared/components/layouts/AuthLayout';
import Input from 'shared/components/Input';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from 'utils/connectors';

const Signup = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState({
    full_name: '',
    email: '',
    password: '',
    rpassword: '',
  });

  const onFormChange = (e) => {
    setError('');
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (form.password && form.password !== form.rpassword) {
      setError('Password mismatch');
      return;
    }

    try {
      setFetching(true);
      await Api.post('/users', form);
      navigate('/singin');
    } catch (err) {
      setFetching(false);
      if (err?.response?.status === 404) {
        setError('No account found with such full_name and password');
      } else {
        const error = err.response?.data?.error;
        setError(error || err.response?.statusText);
      }
    }
  };

  return (
    <AuthLayout>
      <div className='text-center mt-4'>
        <h1 className='h2'>Get started</h1>
        <p className='lead'>Start creating the best possible user experience for you customers.</p>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='m-sm-3'>
            <form onSubmit={onFormSubmit}>
              <Input
                name='full_name'
                type='text'
                label='Full Name'
                placeholder='Enter Full Name'
                className='mb-3'
                value={form.full_name}
                onChange={onFormChange}
                required={true}
              />
              <Input
                name='email'
                type='email'
                label='Email'
                placeholder='Enter Email'
                className='mb-3'
                value={form.email}
                onChange={onFormChange}
                required={true}
              />
              <Input
                name='password'
                type='password'
                label='Password'
                placeholder='Enter Password'
                className='mb-3'
                value={form.password}
                onChange={onFormChange}
                required={true}
                minLength={6}
              />
              <Input
                name='rpassword'
                type='password'
                label='Repeat Password'
                placeholder='Repeat password to doublecheck'
                className='mb-3'
                value={form.rpassword}
                onChange={onFormChange}
                required={true}
                errorText={error}
                minLength={6}
              />
              <div className='d-grid gap-2 mt-3'>
                <button disabled={fetching} className='btn btn-lg btn-primary'>
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='text-center mb-3'>
        Already have account? <Link to='/signin'>Log In</Link>
      </div>
    </AuthLayout>
  );
};

export default Signup;
