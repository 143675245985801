import { Route, Routes } from 'react-router-dom';
import Signin from './routes/Signin/index';
import Signup from './routes/Signup';
import Dashboard from './routes/Dashboard';
import { useEffect } from 'react';
import { getAuthState } from './actions/authActions';
import { useSelector } from 'react-redux';

const App = () => {
  const isAuth = useSelector((state) => state.account);

  useEffect(() => {
    getAuthState();
  }, []);

  if (isAuth === null)
    return (
      <div className='spinner-border m-10' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    );

  return (
    <Routes>
      {!isAuth && <Route path='/signin' element={<Signin />} />}
      {!isAuth && <Route path='/onlyforluxa' element={<Signup />} />}
      <Route path='*' element={isAuth ? <Dashboard /> : <Signin />} />
    </Routes>
  );
};

export default App;
