import { useState } from 'react';
import { useAlert } from 'react-alert';
// import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';

const initialForm = {
  transcript: '',
};

const InsertData = () => {
  const alert = useAlert();
  const [fetching, setFetching] = useState();
  const [file, setFile] = useState();
  const [form, setForm] = useState({ ...initialForm });

  const onSelectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onInsertTranscript = async () => {
    try {
      setFetching(true);
      const form = new FormData();
      form.append('file', file);
      // const data = await Api.post('/meeting/insertJSON', form);
      alert.success('Success');
    } catch (err) {
      alert.error(getError(err));
    } finally {
      setFetching(false);
    }
  };

  const onInsertText = async () => {
    try {
      setFetching(true);
      // const data = await Api.post('/meeting/insertText', form);
      // setForm({ ...initialForm });
    } catch (err) {
      alert.error(getError(err));
    } finally {
      setFetching(false);
    }
  };

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3'>Insert Transcript</h1>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Transcript JSON Data</h5>
            </div>
            <div className='card-body'>
              <input type='file' name='transcript' rows='8' className='form-control' onChange={onSelectFile} />
              <div className='mt-3 text-end'>
                <button className='btn btn-primary px-5' disabled={fetching || !file} onClick={onInsertTranscript}>
                  {fetching ? 'Loading...' : 'Send'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 d-none'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Text Data</h5>
            </div>
            <div className='card-body'>
              <textarea name='text' rows='8' className='form-control' value={form.text} onChange={onFormChange} />
              <div className='mt-3 text-end'>
                <button className='btn btn-primary px-5' disabled={fetching} onClick={onInsertText}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsertData;
