export const getError = (err) => {
  try {
    if (err && err.errors) {
      return err.errors[0].message;
    }
    if (err && err.response && err.response.data) {
      return err.response.data.message;
    }
    if (err && err.response && err.response.statusText) {
      return err && err.response && err.response.statusText;
    }
    if (err && err.data && err.data.message) {
      return err.data.message;
    }
    if (err && err.message) {
      return err.message;
    }
    return err;
  } catch (e) {
    return 'Something went wrong';
  }
};
