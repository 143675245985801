import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { DollarSign, Sliders, Users } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';
import CardInfoBlock from 'shared/components/CardInfoBlock';
import InsightsQuotes from './components/InsightsQuotes';
import QuoteChart from './components/QuoteChart';

const InsightView = () => {
  const params = useParams();
  const alert = useAlert();
  const [insight, setInsight] = useState();
  const [summery, setSummery] = useState();
  const [quotes, setQuotes] = useState([]);
  const [sort, setSort] = useState({ key: 'last_mention', bool: true });
  const [filters, setFilters] = useState({ limit: 10, offset: 1 });
  const [chartData, setChartData] = useState();

  const getInsight = async (id) => {
    try {
      const res = await Api.get(`/insights/quote/generic/${id}`);
      setChartData(res.data.cohort);
      setInsight(res.data.generic);
      setSummery(res.data.summery);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const getInsightQuotes = async (id) => {
    try {
      const params = { ...sort, ...filters };
      const res = await Api.get(`/insights/quote/data/${id}`, { params });
      setQuotes(res.data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  useEffect(() => {
    getInsightQuotes(params?.id);
  }, [sort, filters]);

  useEffect(() => {
    getInsight(params?.id);
  }, []);

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3'>
        <Link to='/insights'>Insights</Link> / Insights Details
      </h1>
      <div className='row'>
        <div className='col-xl-6 col-xxl-7'>
          <div className='card flex-fill w-100'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Insights Details</h5>
            </div>
            <div className='card-body py-3'>
              <h3>{insight?.title}</h3>
              <p className='d-flex align-items-center'>
                <span className='badge bg-black me-2'>Pain Point</span>
              </p>
              <h4>Short Summary</h4>
              <p className='p-2 rounded-3 bg-light mb-0'>
                {summery || ''}
              </p>
            </div>
          </div>
          <div className='w-100'>
            <div className='row'>
              <div className='col-sm-12 d-flex flex-wrap justify-content-between'>
                <div className='col-12 col-md-4 pe-0 pe-md-2'>
                  <CardInfoBlock icon={<Sliders />} title={'Mentions'} value={quotes?.total || 0} />
                </div>
                <div className='col-12 col-md-4 pe-0 pe-md-2'>
                  <CardInfoBlock icon={<Users />} title={'Customers'} value={insight?.accounts_count || 0} />
                </div>
                <div className='col-12 col-md-4'>
                  <CardInfoBlock
                    icon={<DollarSign />}
                    title={'ARR Revenue Impact'}
                    value={`$${insight?.amount || 0}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6 col-xxl-5 d-flex'>
          <div className='card flex-fill w-100'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Insights Histogram</h5>
            </div>
            <div className='card-body py-3'>
              <QuoteChart data={chartData} />
            </div>
          </div>
        </div>
      </div>
      {quotes?.data && (
        <InsightsQuotes quotes={quotes} sort={sort} setSort={setSort} filters={filters} setFilters={setFilters} />
      )}
    </div>
  );
};

export default InsightView;
