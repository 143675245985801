import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';

const Integrations = () => {
  const account = useSelector((state) => state.account);
  const alert = useAlert();
  const hasGongIntegration = account?.company.integrations?.find((i) => i.type === 'gong');

  const onConnectGong = async () => {
    try {
      const res = await Api.get('/auth/gong');
      window.open(res.data, '_blank');
    } catch (err) {
      alert.error(getError(err));
    }
  };

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3 d-flex align-items-center justify-content-between'>Integrations</h1>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <div className='card'>
            <div className='card-header text-bg-primary'>
              <h5 className='card-title mb-0 text-white'>Gong Account</h5>
            </div>
            <div className='card-body'>
              <p>
                <b className='me-2'>Connection:</b>
                <span className={hasGongIntegration ? 'text-success' : ''}>
                  {hasGongIntegration ? 'Connected' : 'Not Connected'}
                </span>
              </p>
              <button className='btn btn-primary' onClick={onConnectGong} disabled={hasGongIntegration}>
                Connect
              </button>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='card'>
            <div className='card-header text-bg-warning'>
              <h5 className='card-title mb-0 text-white'>Zoom Account</h5>
            </div>
            <div className='card-body'>
              <p>
                <b className='me-2'>Connection: </b> Not available
              </p>
              <button className='btn btn-primary' disabled={true}>
                Connect
              </button>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='card'>
            <div className='card-header text-bg-warning'>
              <h5 className='card-title mb-0 text-white'>Zendesk Account</h5>
            </div>
            <div className='card-body'>
              <p>
                <b className='me-2'>Connection: </b> Not available
              </p>
              <button className='btn btn-primary' disabled={true}>
                Connect
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
