import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { BrowserRouter } from 'react-router-dom';
import 'scss/app.scss';
import { Provider } from 'react-redux';
import store from 'redux/store';
import AlertTemplate from 'shared/components/layouts/AlertTemplate';

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '20px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 200,
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
