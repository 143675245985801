import { logout } from 'app/actions/authActions';
import { User } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDownMenu from 'shared/components/DropDownMenu';

const NavBar = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);

  const onToggleSidebar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.querySelector('nav.sidebar')?.classList.toggle('collapsed');
  };

  const toProfile = () => navigate('/profile');

  return (
    <nav className='navbar navbar-expand navbar-light navbar-bg'>
      <div className='sidebar-toggle js-sidebar-toggle' onClick={onToggleSidebar}>
        <i className='hamburger align-self-center'></i>
      </div>

      <div className='navbar-collapse collapse'>
        <ul className='navbar-nav navbar-align'>
          <DropDownMenu
            activeEl={
              <>
                <User /> <span className='text-dark'>{account.full_name} </span>
              </>
            }
          >
            <DropDownMenu.Item divider onClick={toProfile}>
              <User width='24' height='24' className='feather feather-user align-middle me-1' />{' '}
              Profile
            </DropDownMenu.Item>
            <DropDownMenu.Item onClick={logout}>Log out</DropDownMenu.Item>
          </DropDownMenu>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
