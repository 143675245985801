import { useState } from 'react';
import { Api } from 'utils/connectors';
import { loginUser } from 'app/actions/authActions';
import AuthLayout from 'shared/components/layouts/AuthLayout';
import Input from 'shared/components/Input';

const Signin = () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState({
    username: '',
    password: '',
  });

  const onFormChange = (e) => {
    setError('');
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setFetching(true);
      const body = {
        username: form.username.toLocaleLowerCase(),
        password: form.password,
      };
      const data = await Api.post('/auth/login', body);
      loginUser(data.data.access_token);
    } catch (err) {
      if (err?.response?.status === 404) {
        setError('No account found with such username and password');
      } else {
        const error = err.response?.data?.error || err.response?.data?.message;
        setError(error || err.response?.statusText);
      }
    } finally {
      setFetching(false);
    }
  };

  return (
    <AuthLayout>
      <div className='text-center mt-4'>
        <h1 className='h2'>Welcome back!</h1>
        <p className='lead'>Sign in to your account to continue</p>
      </div>

      <div className='card'>
        <div className='card-body'>
          <div className='m-sm-3'>
            <form onSubmit={onFormSubmit}>
              <Input
                name='username'
                type='text'
                label='Email'
                placeholder='Enter your email'
                className='mb-3'
                value={form.username}
                onChange={onFormChange}
                required={true}
                autoComplete={'email'}
              />
              <Input
                name='password'
                type='password'
                label='Password'
                placeholder='Enter Password'
                className='mb-3'
                value={form.password}
                onChange={onFormChange}
                required={true}
                minLength={6}
                errorText={error}
                autoComplete={'password'}
              />
              <div>
                <div className='form-check align-items-center'>
                  <input
                    id='customControlInline'
                    type='checkbox'
                    className='form-check-input'
                    value='remember-me'
                    name='remember-me'
                  />
                  <label className='form-check-label text-small' htmlFor='customControlInline'>
                    Remember me
                  </label>
                </div>
              </div>
              <div className='d-grid gap-2 mt-3'>
                <button disabled={fetching} className='btn btn-lg btn-primary'>
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div className='text-center mb-3'>
        Don't have an account? <Link to='/signup'>Create Account.</Link>
      </div> */}
    </AuthLayout>
  );
};

export default Signin;
