import { Api, setAuthToken } from 'utils/connectors';
import { onLogout, onSetAccount } from './auth';
import { getFromStore, removeFromStore, saveToStore } from 'utils/storeHelpers';
import store from 'redux/store';

export const clearForLogout = () => {
  store.dispatch(onLogout());
  removeFromStore('auth');
  setAuthToken('', Api);
  store.dispatch(onSetAccount(false));
};

export const getProfile = async () => {
  try {
    const data = await Api.get('/auth/profile');
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAuthState = async () => {
  const token = getFromStore('auth');

  if (token) {
    // set common headers
    setAuthToken(token, Api);

    try {
      const user = await getProfile();
      await store.dispatch(onSetAccount(user));
    } catch (err) {
      clearForLogout();
    }
  } else clearForLogout();
};

export const loginUser = async (token) => {
  try {
    saveToStore('auth', token);
    setAuthToken(token, Api);
    const dbUser = await getProfile();
    await store.dispatch(onSetAccount(dbUser));
  } catch (err) {
    clearForLogout();
  }
};

export const logout = () => {
  clearForLogout();
};
