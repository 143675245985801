import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Clock, DollarSign, Sliders, Truck } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';
import CardInfoBlock from 'shared/components/CardInfoBlock';
import dayjs from 'dayjs';
import CallInsights from './components/CallInsights';
import GongContent from 'shared/components/GongContent';

const CallsView = () => {
  const params = useParams();
  const alert = useAlert();
  const [data, setData] = useState();
  const [quote, setQuote] = useState();

  const getCall = async (id) => {
    try {
      const res = await Api.get(`/users/company/calls/${id}`);
      setData(res.data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const secondsToHms = (second) => {
    if (!second) return '-';
    return new Date(second * 1000).toISOString().substr(11, 8);
  };

  useEffect(() => {
    getCall(params?.id);
  }, []);

  const hasCRMInfo = !!data?.opportunity?.amount;

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3'>
        <Link to='/calls'>Calls</Link> / Call Details
      </h1>
      <div className='row'>
        <div className='col-xl-6 col-xxl-7'>
          <div className='card flex-fill w-100'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Call Details</h5>
            </div>
            <div className='card-body py-3'>
              <h3>{data?.title}</h3>
              <p className='d-flex align-items-center'>
                {dayjs(data?.started).format('DD MMM YYYY')} with {data?.system}
                <span className='mx-1'>| {data?.parties.length} Speakers</span>
              </p>
              <h4>Short Summary</h4>
              <p className='p-2 rounded-3 bg-light mb-0'>
                {data?.summery}
              </p>
            </div>
          </div>
        </div>
        <div className='col-xl-6 col-xxl-5 d-flex'>
          <div className='w-100'>
            <div className='row'>
              <div className='col-sm-6'>
                {hasCRMInfo && (
                  <CardInfoBlock
                    icon={<DollarSign />}
                    title={'Revenue'}
                    value={`$${data?.opportunity?.amount}`}
                  ></CardInfoBlock>
                )}
                <CardInfoBlock icon={<Sliders />} title={'Insights'} value={data?.quotes?.length || 0}></CardInfoBlock>
              </div>
              <div className='col-sm-6'>
                {hasCRMInfo && (
                  <CardInfoBlock
                    icon={<Truck />}
                    title={'Deal Stage'}
                    value={data?.opportunity?.deal_stage}
                  ></CardInfoBlock>
                )}
                <CardInfoBlock icon={<Clock />} title={'Duration'} value={secondsToHms(data?.duration)}></CardInfoBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.quotes && <CallInsights data={data?.quotes} setQuote={setQuote} />}
      {quote && <GongContent quote={quote} handleClose={() => setQuote(null)} />}
    </div>
  );
};

export default CallsView;
