/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Children, isValidElement, cloneElement, useState, useRef } from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const DropDownMenu = ({ state, stateChange, children, activeEl }) => {
  const [show, setShow] = useState(false);
  const isOpened = state ? state : show;
  const menu = useRef();
  useOutsideClick(menu, () => setShow(false));

  const handleStateChange = (bool) => {
    if (stateChange) {
      stateChange(bool);
    } else {
      setShow(bool);
    }
  };

  const enhancedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { onBeforeClick: handleStateChange.bind(null, false) });
    }
    return child;
  });

  return (
    <li className='nav-item dropdown' ref={menu}>
      <div className='nav-icon dropdown-toggle d-inline-block d-sm-none' data-bs-toggle='dropdown'>
        <i className='align-middle' data-feather='settings'></i>
      </div>
      <div
        className={`nav-link dropdown-toggle cursor-pointer d-none d-sm-inline-block ${
          isOpened ? 'show' : ''
        }`}
        data-bs-toggle='dropdown'
        onClick={() => setShow(!show)}
      >
        {activeEl}
      </div>
      <div
        className={`dropdown-menu dropdown-menu-end ${isOpened ? 'show' : ''}`}
        data-bs-popper='static'
      >
        {enhancedChildren}
      </div>
    </li>
  );
};

DropDownMenu.Item = ({ children, divider, onBeforeClick, onClick, to }) => {
  const onItemClick = (e) => {
    if (onBeforeClick) {
      e.stopPropagation();
      e.preventDefault();
      onBeforeClick();
    }
    onClick();
  };
  return (
    <>
      <a className='dropdown-item' href='#' onClick={onItemClick}>
        {children}
      </a>
      {divider && <div className='dropdown-divider' />}
    </>
  );
};

DropDownMenu.defaultProps = {
  enable: true,
  position: 'right',
};

export default DropDownMenu;
