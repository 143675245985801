import { Link } from 'react-router-dom';
import TableHeader from 'shared/components/TableHeader';

const listHeaders = [
  { name: 'Insight', key: '', classContentName: '' },
  { name: 'Quote', key: '', classContentName: '' },
  { name: 'Time Stamp', key: '', classContentName: 'text-nowrap' },
];

const CallInsights = ({ data, setQuote }) => {
  const milisecondsToTime = (miliseconds) => {
    if (!miliseconds) return '-';
    return new Date(Number(miliseconds)).toISOString().substr(11, 8);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='card'>
          <div className='card-header'>
            <h5 className='card-title mb-0'>List of Insights</h5>
          </div>
          <div className='card-body'>
            <div className='overflow-auto'>
              <table className='table'>
                <TableHeader items={listHeaders} />
                <tbody>
                  {data?.map((item, i) => {
                    const insightId = item.quotes_insights_map.insights_id;
                    return (
                      <tr key={i}>
                        <td>
                          <Link className='text-body text-decoration-underline' to={`/insights/${insightId}`}>
                            {item.title}
                          </Link>
                        </td>
                        <td className='text-decoration-underline cursor-pointer' onClick={() => setQuote(item)}>
                          {item.quote}
                        </td>
                        <td className='text-end'>{milisecondsToTime(item.timestamp)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallInsights;
