import { Loader } from 'react-feather';

const Loading = ({ className }) => {
  return (
    <div className={className}>
      <Loader />
    </div>
  );
};

export default Loading;
