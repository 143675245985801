import { CornerRightDown, CornerRightUp } from 'react-feather';

const TableHeader = ({ items, sort, setSort }) => {
  const onSortWithKey = (item) => {
    if (!item.key || !setSort) return;
    const bool = sort.key === item.key ? !sort.bool : sort.bool;
    setSort({ key: item.key, bool });
  };

  return (
    <thead>
      <tr>
        {items.map((item, i) => {
          const hasSort = sort && sort.key === item.key;
          return (
            <th scope='col' key={i} className={`${item.className || ''}`} onClick={onSortWithKey.bind(null, item)}>
              <div className={`${item.key ? 'cursor-pointer' : ''} text-center d-flex align-items-center ${item.classContentName || ''}`}>
                {item.name}
                {hasSort && (
                  <span className='ms-2'>
                    {sort.bool ? <CornerRightDown width={14} height={14} /> : <CornerRightUp width={14} height={14} />}
                  </span>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
