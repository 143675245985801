import { Route, Routes } from 'react-router-dom';
import Footer from 'shared/components/layouts/Footer';
import NavBar from 'shared/components/layouts/NavBar';
import Sidebar from 'shared/components/layouts/SideBar';
import Profile from './routes/Profile';
import Insights from './routes/Insights';
import InsightView from './routes/InsightView';
import InsertData from './routes/InsertData';
import Quotes from './routes/Quotes';
import Jobs from './routes/Jobs';
import Calls from './routes/Calls';
import CallsView from './routes/CallsView';
import Integrations from './routes/Integrations';
import GongCallback from './routes/Integrations/components/GongCallback';

const Dashboard = () => {
  return (
    <div className='wrapper'>
      <Sidebar />
      <div className='main'>
        <NavBar />
        <main className='content'>
          <div className='container-fluid p-0'>
            <Routes>
              <Route path='/auth/gong/callback' element={<GongCallback />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/calls' element={<Calls />} />
              <Route path='/calls/:id' element={<CallsView />} />
              <Route path='/insights' element={<Insights />} />
              <Route path='/insights/:id' element={<InsightView />} />
              <Route path='/integrations' element={<Integrations />} />
              <Route path='/quotes' element={<Quotes />} />
              <Route path='/insert' element={<InsertData />} />
              <Route path='/jobs' element={<Jobs />} />
              <Route path='*' element={<Calls />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
