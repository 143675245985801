const Footer = ({ children }) => {
  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <div className='row text-muted'>
          <div className='col-12 col-md-6 text-start'>
            Copyright 2024 Luxa Technologies Inc. All rights reserved.
          </div>
          <div className='col-12 col-md-6 text-center text-md-end mt-2 mt-md-0'>
            <ul className='list-inline'>
              <li className='list-inline-item'>
                <a className='text-muted' href='https://www.luxa.ai/' rel="noreferrer" target='_blank'>
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
