import React from 'react';

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div style={{ ...style }} className='alert-template'>
      {/* {options.type === 'info' && <InfoIcon />}
      {options.type === 'success' && <SuccessIcon />}
      {options.type === 'error' && <ErrorIcon />} */}
      <span style={{ flex: 2 }} className='ml-5'>
        {message}
      </span>
      {/* <button onClick={close}>close</button> */}
    </div>
  );
};

export default AlertTemplate;
