export const saveToStore = (name, value) => {
  if (!localStorage || !localStorage.setItem) return;
  localStorage.setItem(name, value);
};

export const getFromStore = (name, defaultValue = false) => {
  if (!localStorage || !localStorage.getItem) return defaultValue;
  const res = localStorage.getItem(name);
  return res === undefined || res === null ? defaultValue : res;
};

export const removeFromStore = name => {
  if (!localStorage || !localStorage.removeItem) return;
  localStorage.removeItem(name);
};

export const removeAllStore = () => {
  if (!localStorage || !localStorage.clear) return;
  localStorage.clear();
};
