import React from 'react';

const Input = ({
  id,
  type,
  name,
  label,
  value,
  onChange,
  placeholder,
  required,
  readOnly,
  errorText,
  className = 'mb-md-10 mb-8',
  inputClassName,
  icon,
  minLength,
  autoComplete,
}) => {
  return (
    <div className={`${className || ''}`}>
      {label && (
        <label className='form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        minLength={minLength}
        autoComplete={autoComplete}
        className={`form-control form-control-lg is-${errorText ? 'invalid' : ''} ${
          inputClassName || ''
        }`}
      />
      {icon && (
        <span className='form-float-icon d-flex flex-column justify-content-center pe-6'>
          {icon}
        </span>
      )}
      {errorText && <div className='invalid-feedback mt-1'>{errorText}</div>}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
};

export default Input;
