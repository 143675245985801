import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Pagination } from 'rsuite';
import TableHeader from 'shared/components/TableHeader';

const listHeaders = [
  { name: 'Quote', key: 'quote_title', classContentName: '' },
  { name: 'Customer', key: 'account_names', classContentName: '' },
  { name: 'Revienue', key: 'amount', classContentName: '' },
  { name: 'Call', key: 'call_title', classContentName: '' },
  { name: 'Date', key: 'quote_created_at', classContentName: 'justify-content-end' },
];

const InsightsQuotes = ({ quotes, sort, setSort, filters, setFilters }) => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='card'>
          <div className='card-header'>
            <h5 className='card-title mb-0'>List of Quotes</h5>
          </div>
          <div className='card-body'>
            <div className='overflow-auto'>
              <table className='table'>
                <TableHeader items={listHeaders} sort={sort} setSort={setSort} />
                <tbody>
                  {quotes?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.quote_title}</td>
                        <td>{item.account_names?.[0]}</td>
                        <td>{item.amount || '-'}</td>
                        <td>
                          <Link className='text-body text-decoration-underline' to={`/calls/${item.call_id}`}>
                            {item.call_title || '-'}
                          </Link>
                        </td>
                        <td className='d-flex justify-content-end'>
                          {dayjs(item.quote_created_at).format('DD MMM YYYY')}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='d-flex align-items-center justify-content-around justify-content-md-end py-3'>
              <Pagination
                prev
                next
                size='md'
                total={quotes?.total || 0}
                limit={filters.limit}
                activePage={filters.offset}
                onChangePage={(offset) => setFilters({ ...filters, offset })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightsQuotes;
