import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const QuoteChart = ({ data }) => {
  const counts = data?.map((i) => i.count) || [];
  const labels = data?.map((i) => dayjs(i.date_trunc).format('DD MMM YYYY')) || [];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Insights',
        data: [...counts],
        backgroundColor: 'rgb(255, 191, 35)',
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
};

export default QuoteChart;
