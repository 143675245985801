const Profile = () => {
  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3'>Profile Page</h1>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Empty card</h5>
            </div>
            <div className='card-body'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
