import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import TableHeader from 'shared/components/TableHeader';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';

const listHeaders = [
  { name: 'Description', key: 'title', classContentName: '' },
  { name: 'Exact quote', key: 'quote', classContentName: '' },
  { name: 'Customer', key: 'customers', classContentName: 'justify-content-center' },
  { name: 'Actions', key: '', classContentName: 'justify-content-center' },
];

const Quotes = () => {
  const alert = useAlert();
  const [sort, setSort] = useState({ key: 'title', bool: true });
  const [data, setData] = useState([]);

  const getQuotes = async () => {
    try {
      const res = await Api.get('/insights/');
      setData(res.data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const openCall = (item) => {
    window.open(`https://us-48113.app.gong.io/call?id=${item.call_id}`, '_blank');
  };

  useEffect(() => {
    getQuotes();
  }, [sort]);

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3'>Quotes</h1>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>List of quotes</h5>
            </div>
            <div className='card-body'>
              <table className='table'>
                <TableHeader items={listHeaders} sort={sort} setSort={setSort} />
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td className='text-center'>{item.company_id}</td>
                        <td>
                          <button
                            className='btn btn-sm btn-outline-dark rounded-2 text-nowrap'
                            onClick={openCall.bind(null, item)}
                          >
                            Open Call
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotes;
