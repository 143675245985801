import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';

const Jobs = () => {
  const alert = useAlert();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  const getJobs = async () => {
    try {
      const res = await Api.get('/scheduler/jobs');
      setData(res.data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const runJob = async () => {
    try {
      setFetching(true);
      await Api.get('/scheduler/execute');
      await getJobs();
    } catch (err) {
      alert.error(getError(err));
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div className='container-fluid p-0'>
      <h1 className='h3 mb-3 d-flex align-items-center justify-content-between'>
        Jobs
        <button className='btn btn-primary' disabled={fetching} onClick={runJob}>
          Run job
        </button>
      </h1>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>List of Jobs</h5>
            </div>
            <div className='card-body'>
              {!!data?.length && (
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Title</th>
                      <th scope='col'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td>Not Started</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {!data?.length && 'No data'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
