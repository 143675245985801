import { createReducer } from "redux/utils";

export const ON_SET_ACCOUNT = '@@auth/ON_SET_ACCOUNT';
export const ON_LOGOUT = '@@auth/ON_LOGOUT';

export const onSetAccount = payload => ({ type: ON_SET_ACCOUNT, payload });
export const onLogout = () => ({ type: ON_LOGOUT });

const accountHandlers = {
  [ON_SET_ACCOUNT]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

export const accountInitialState = null;

export const accountReducer = createReducer(accountInitialState, accountHandlers);
