import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/errors';
import { CheckPicker, DateRangePicker, Input, InputGroup, Pagination } from 'rsuite';
import TableHeader from 'shared/components/TableHeader';
import Loading from 'shared/components/Loading';
import { Search } from 'react-feather';
import useDebounce from 'shared/hooks/useDebounce';

const listHeaders = [
  { name: 'Insight', key: 'title', classContentName: '' },
  // { name: 'Number of quotes', key: 'mention_count', classContentName: 'justify-content-end' },
  { name: '# of customers', key: 'accounts_count', classContentName: 'justify-content-end' },
  { name: 'Customers', key: 'account_names', classContentName: '' },
  { name: 'Revenue impact', key: 'amount', classContentName: '' },
  { name: 'Last mention date', key: 'last_mention', classContentName: 'justify-content-end' },
];

const Insights = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [customers, setCustomers] = useState([]);
  const [sort, setSort] = useState({ key: 'last_mention', bool: true });
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search, 500);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 1,
    customers: [],
    startDate: null,
    endDate: null,
  });

  const getCustomers = async () => {
    try {
      const res = await Api.get('/users/company/customers');
      const data = res.data.map((item) => ({ label: item.account_name, value: item.account_id }));
      setCustomers(data);
    } catch (err) {
      alert.error(getError(err));
    }
  };

  const getInsights = async () => {
    try {
      setFetching(true);
      const params = { ...sort, ...filters, search: debounceSearch || '' };
      const res = await Api.get('/insights/pain_points', { params });
      setData(res.data);
    } catch (err) {
      alert.error(getError(err));
    } finally {
      setFetching(false);
    }
  };

  const onSelectCustomers = (customers) => {
    setFilters({ ...filters, customers });
  };

  const onSelectDate = (dateRange) => {
    if (!dateRange) {
      setFilters({ ...filters, dateRange: { startDate: null, endDate: null } });
      return;
    }
    const [start, end] = dateRange;
    setFilters({ ...filters, startDate: start, endDate: end });
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const goToDetails = (item) => {
    navigate(`/insights/${item.id}`);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    getInsights();
  }, [sort, filters, debounceSearch]);

  return (
    <div className='container-fluid p-0'>
      <div className='d-flex flex-wrap mb-3 align-items-center justify-content-between'>
        <h1 className='h3 d-flex align-items-center'>Insights {fetching && <Loading className='ms-2' />}</h1>
        <div className='d-flex flex-wrap align-items-center justify-content-end col-12 col-md-8'>
          <InputGroup size='md' className='col-12 col-md col-md-none'>
            <Input placeholder={'Search insights...'} value={search} onChange={onSearchChange} />
            <InputGroup.Addon>
              <Search />
            </InputGroup.Addon>
          </InputGroup>
          <CheckPicker label='Customers' className='ms-0 ms-md-2 mt-2 mt-md-0 col-12 col-md-3' data={customers} onChange={onSelectCustomers} />
          <DateRangePicker
            size='md'
            className='ms-0 ms-md-2 mt-2 mt-md-0 col-12 col-md-5'
            placeholder='Select Date Range'
            showOneCalendar
            onChange={onSelectDate}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>List of Insights</h5>
            </div>
            <div className='card-body'>
              <div className='overflow-auto'>
                <table className='table table-hover'>
                  <TableHeader items={listHeaders} sort={sort} setSort={setSort} />
                  <tbody>
                    {data?.data?.map((item, i) => {
                      return (
                        <tr key={i} className='cursor-pointer' onClick={goToDetails.bind(null, item)}>
                          <td>{item.title}</td>
                          {/* <td className='justify-content-center d-flex'>{item.mention_count}</td> */}
                          <td className='justify-content-center d-flex'>{item.accounts_count}</td>
                          <td>
                            {item.account_names?.map((row, i) => {
                              if (i > 3) return null;
                              if (i === 3)
                                return (
                                  <span key={row} className='badge bg-info mx-1'>
                                    +{item.account_names.length - 3}
                                  </span>
                                );
                              return (
                                <span key={row} className='badge bg-info mx-1'>
                                  {row}
                                </span>
                              );
                            })}
                          </td>
                          <td>{item.amount ? `$${item.amount}` : '-'}</td>
                          <td className='d-flex justify-content-end'>
                            {dayjs(item.last_mention).format('DD MMM YYYY')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='d-flex align-items-center justify-content-around justify-content-md-end py-3'>
                <Pagination
                  prev
                  next
                  size='md'
                  total={data?.total || 0}
                  limit={filters.limit}
                  activePage={filters.offset}
                  onChangePage={(offset) => setFilters({ ...filters, offset })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;
